import { useEffect } from "react";
import { useLocation } from "react-router";
import { useDispatch } from "react-redux";
import { toggleSlideOver } from "../redux/slices/slideOverSlice"
import { toggleModal } from "../redux/slices/modalSlice"

/**
useCleanupLayover is a custom React hook for cleaning up slide over and modal
components when navigating to a new page. It dispatches Redux actions on route change.
*/
const useCleanupLayover = () => {
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(toggleSlideOver({
      show: false,
      show1: false,
      show2: false
    }))

    dispatch(toggleModal({
      show: false,
      show1: false,
      show2: false
    }))
  }, [location.pathname])
}

export default useCleanupLayover
import { classNames } from "../../../utils/classNames"

const Card = ({className, children, title, ...rest}) => {
  return (
    <div
      className={classNames(
        `w-full h-fit bg-stone-900 shadow-md rounded-lg px-5 py-5`,
        className
      )}
      {...rest}
    >
      <div>
        {
          title && <p className="text-sm font-semibold mb-4">{title}</p>
        }
        {children}
      </div>
    </div>
  )
}

export default Card

import { PaintBrushIcon, BoltIcon, DocumentArrowDownIcon, ChevronRightIcon } from "@heroicons/react/24/outline"
import { useNavigate } from "react-router-dom"

const ArtCard = ({ art }) => {

  const navigate = useNavigate()

  const handleNavigateArtPiece = () => {
    navigate(`/art/3js/${art.id}`)
  }

  return (
    <div className="w-full h-fit bg-stone-900 rounded-lg shadow-lg hover:shadow-2xl transition duration-300 
      flex flex-col mx-auto">

      <div className="flex flex-col md:flex-row">
        <img className="h-32 w-full md:h-64 md:w-[18rem] object-cover rounded-tl-lg" src={art.imgUrl} alt="" />
        <div className="flex flex-col sm:flex-row md:flex-col mx-5 my-6 justify-between space-y-4 sm:space-y-0">
          <div className="flex flex-col space-y-2">
            <p className="text-xs font-bold leading-normal">Name: <span className="font-normal">{art.name}</span></p>
            <p className="text-xs font-bold leading-normal line-clamp-1">Description: <span className="font-normal">{art.description}</span></p>
            <p className="text-xs font-bold leading-normal">Date: <span className="font-normal">{art.date}</span></p>
            <p className="text-xs font-bold leading-normal">Skill: <span className="font-normal">Three.js, Cinema4D</span></p>
          </div>

          <div className="flex flex-col space-y-3 md:w-full">
            <div className="flex flex-row items-center space-x-2">
              <PaintBrushIcon className="h-4 w-4 text-yellow-500" />
              <p className="text-xs font-bold truncate">Polygon count: <span className="font-normal">{art.details.polyCount}</span></p>
            </div>
            <div className="flex flex-row items-center space-x-2">
              <BoltIcon className="h-4 w-4 text-green-500" />
              <p className="text-xs font-bold truncate">Load time: <span className="font-normal">{art.details.loadTime}</span></p>
            </div>
            <div className="flex flex-row items-center space-x-2">
              <DocumentArrowDownIcon className="h-4 w-4 text-purple-500" />
              <p className="text-xs font-bold truncate">File size: <span className="font-normal">{art.details.fileSize}</span></p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-row justify-between w-full py-2 px-2 cursor-pointer hover:bg-green-600 bg-green-800
        transition duration-300 rounded-b-lg"
        onClick={handleNavigateArtPiece}>
        <ChevronRightIcon className="h-9 w-9 p-2 ml-auto" />
      </div>
      <div>

      </div>
    </div>
  )
}

export default ArtCard
import Spline from '@splinetool/react-spline';
import { useLocation } from 'react-router-dom';
import PageContainer from '../shared/containers/PageContainer.component';
import { useState } from 'react';
import LoadingState from '../shared/preloader/LoadingState.component';
import { useAutoAnimate } from '@formkit/auto-animate/react';

const ThreeJSRender = () => {

  const location = useLocation()
  const id = location.pathname.split("/").at(-1)
  const [loading, setLoading] = useState(true)
  const [parent] = useAutoAnimate()

  return (
    <PageContainer>
      <div className='w-full h-full flex flex-col space-y-4 px-6 py-6 shadow-2xl rounded-lg bg-stone-900'>
        <div className='flex flex-row w-full h-fit space-x-2'>
          <button className='text-xs px-4 py-2 bg-signature w-fit rounded-md hover:bg-stone-800 duration-300'>View info</button>
          <button className='text-xs px-4 py-2 bg-signature w-fit rounded-md hover:bg-stone-800 duration-300'>Scene settings </button>
        </div>
        <div ref={parent} className='h-full w-full relative'>
          <Spline className='rounded-lg' scene={`https://prod.spline.design/${id}/scene.splinecode`}
            onLoad={() => { setLoading(false) }} />
          {
            loading && 
            <div className='absolute flex flex-col space-y-4 justify-center items-center h-full w-full'>
              <p className='text-xs'>Loading...</p>
              <LoadingState color="white" secondaryColor="gray"/>
            </div>
          }
        </div>
      </div>
    </PageContainer>
  )
}
export default ThreeJSRender

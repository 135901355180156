import PageContainer from "../components/shared/containers/PageContainer.component"
import { ReactComponent as GithubIcon } from '../assets/icons/github.svg'
import { ReactComponent as LinkedInIcon } from '../assets/icons/linkedin.svg'
import { ReactComponent as GmailIcon } from '../assets/icons/gmail.svg'

const Home = () => {
  return (
    <PageContainer>
      <div>
        <p className="text-white text-3xl md:text-4xl xl:text-5xl font-semibold tracking-tight font-rounded
          sm:max-w-[80%] xl:max-w-[60%] leading-tight">
          Badass Software Engineer.</p>
        <p className="mt-6 xl:mt-8 text-sm">I'm Steve Nguyen, a software engineer based in NY and I have 45 fish</p>
        <div className="flex flex-row space-x-4 mt-6 xl:mt-8">
          <a href="https://github.com/tuanminh160302" target="_blank"><GithubIcon className="h-5 w-5 fill-gray-200 cursor-pointer hover:fill-signature_yellow transition duration-300" /></a>
          <a href="https://www.linkedin.com/in/steve-nguyen-a33607153/" target="_blank"><LinkedInIcon className="h-5 w-5 fill-gray-200 cursor-pointer hover:fill-signature_yellow transition duration-300" /></a>
          <a href="mailto:canhtuan09@gmail.com"><GmailIcon className="h-5 w-5 fill-gray-200 cursor-pointer hover:fill-signature_yellow transition duration-300" /></a>
        </div>

        <div className="bg-stone-900 rounded-xl mt-8">
          <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:flex lg:items-center lg:py-16 lg:px-8">
            <div className="lg:w-0 lg:flex-1">
              <h2 className="text-xl font-bold tracking-tight text-white" id="newsletter-headline">
                Sign up for newsletter
              </h2>
              <p className="mt-3 max-w-3xl text-sm leading-6 text-gray-300">
                Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui Lorem cupidatat commodo. Elit sunt amet
                fugiat veniam occaecat fugiat.
              </p>
            </div>
            <div className="mt-8 lg:mt-0 lg:ml-8 lg:flex-1">
              <form className="sm:flex">
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email-address"
                  type="email"
                  autoComplete="email"
                  required
                  className="text-xs w-full rounded-md border border-transparent px-5 py-3 placeholder-gray-500 focus:border-signature_yellow 
                    focus:ring-2 focus:ring-signature_yellow focus:ring-offset-2 focus:ring-offset-gray-800"
                  placeholder="Enter your email"
                />
                <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                  <button
                    type="submit"
                    className="flex w-full items-center justify-center rounded-md border border-transparent bg-signature_yellow px-5 py-3 
                      text-sm font-medium text-white hover:bg-signature_yellow focus:outline-none focus:ring-2 
                      focus:ring-signature_yellow focus:ring-offset-2 focus:ring-offset-gray-800"
                  >
                    Notify me
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </PageContainer>
  )
}

export default Home
import { EyeIcon } from '@heroicons/react/20/solid'
import PageContainer from "../shared/containers/PageContainer.component"
import { works } from "../../data/workSummary.data"
import { useLocation } from "react-router-dom"
import { useEffect, useState, useMemo } from "react"
import { useNavigate } from 'react-router-dom'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import Card from '../shared/containers/Card.component'
import Modal from '../shared/layovers/Modal.component'
import { toggleModal } from '../../redux/slices/modalSlice'
import { useSelector, useDispatch } from 'react-redux'

const WorkDetails = () => {
  const [displayType, setDisplayType] = useState(0) //0=undefined; 1=summary; 2=details
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const showModal = useSelector(state => state.modal.show)
  const [expandImgUrl, setExpandImgUrl] = useState("")
  const [parent] = useAutoAnimate({ duration: 300 })
  const workId = location.pathname.split("/")?.[2]
  const moduleId = location.pathname.split("/")?.[3]
  const work = works.find(w => w.id === workId)
  const workModules = work?.work
  const module = workModules?.[moduleId]

  useEffect(() => {
    if (location.pathname.split("/")?.length === 3) {
      setDisplayType(1)
    } else if (location.pathname.split("/")?.length === 4) {
      setDisplayType(2)
    }
  }, [location.pathname])

  const handleRedirectView = (moduleIdx) => {
    navigate(`/work/${workId}/${moduleIdx}`)
  }

  const handleOpenModal = (src) => {
    setExpandImgUrl(src)
    dispatch(toggleModal({ show: true }))
  }

  const handleCloseModal = () => {
    setExpandImgUrl("")
    dispatch(toggleModal({ show: false }))
  }

  const summaryComponent = useMemo(() => {
    return (
      <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {workModules.map((work) => (
          <li key={work?.id} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-stone-900 shadow">
            <div className="flex w-full items-center justify-between space-x-6 p-6">
              <div className="flex-1 truncate">
                <div className="flex items-center space-x-3">
                  <h3 className="truncate text-sm font-medium text-white">{work?.name}</h3>
                </div>
                <p className="mt-1 truncate text-sm text-gray-400">{work?.description}</p>
              </div>
            </div>
            <div>
              <div className="-mt-px flex divide-x divide-gray-200">
                <div className="-ml-px flex w-0 flex-1">
                  <p
                    onClick={() => { handleRedirectView(work?.id) }}
                    className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-white cursor-pointer hover:bg-stone-800 transition duration-300"
                  >
                    <EyeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    View
                  </p>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    )
  }, [workId])

  const imgViewComponent = useMemo(() => {
    return (
      <ul role="list" className="grid grid-cols-2 gap-x-2 gap-y-4 sm:grid-cols-3 sm:gap-x-3 lg:grid-cols-4 xl:gap-x-4">
        {module?.imgs?.map((file, idx) => (
          <li key={idx} className="relative">
            <div
              onClick={() => {
                handleOpenModal(file.source)
              }}
              className="group aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-lg border-2 hover:border-signature_yellow transition duration-150 cursor-pointer">
              <img src={file?.source} alt="" className="pointer-events-none object-cover group-hover:opacity-75 transition duration-150" />
              <button type="button" className="absolute inset-0 focus:outline-none pointer-events-none">
                <span className="sr-only">View details for {file?.title}</span>
              </button>
            </div>
            <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">{file?.title}</p>
          </li>
        ))}
      </ul>
    )
  }, [moduleId])

  const linkViewComponent = useMemo(() => {
    return (
      <div className="overflow-hidden bg-stone-900 shadow sm:rounded-md">
        <ul role="list" className="divide-y divide-gray-200">
          {module?.links?.map((file, idx) => (
            <li key={idx}>
              <a target="_blank" href={file?.source} className="block hover:bg-stone-800 transition duration-150">
                <div className="px-4 py-4 sm:px-6">
                  <div className="flex items-center justify-between">
                    <p className="truncate text-sm font-medium text-signature_yellow">{file?.title}</p>
                    <div className="ml-2 flex flex-shrink-0">
                      <p className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                        {file?.type}
                      </p>
                    </div>
                  </div>
                  <div className="mt-2 sm:flex sm:justify-between">
                    <div className="sm:flex">
                      <p className="flex items-center text-sm text-gray-500">
                        Archive
                      </p>
                      <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                        Google Drive
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </li>
          ))}
        </ul>
      </div>
    )
  }, [moduleId])

  const detailsComponent = useMemo(() => {
    return (
      <div className='flex flex-col space-y-6'>
        <Card
          className="border-2 border-signature_yellow"
          title={module?.name}
        >
          <div className="flex flex-col space-y-2">
            <p className="text-xs">
              Description:
              {" "}
              <span className="leading-5 font-light text-gray-400">{module?.description}</span>
            </p>

            <p className="text-xs">
              Module Type:
              {" "}
              <span className="leading-5 font-light text-gray-400">{module?.moduleType}</span>
            </p>

            <p className="text-xs">
              Product Type:
              {" "}
              <span className="leading-5 font-light text-gray-400">{module?.productType}</span>
            </p>
          </div>
        </Card>
        {
          module?.productType === "img" && <>{imgViewComponent}</>
        }
        {
          module?.productType === "link" && <>{linkViewComponent}</>
        }
        {
          module?.productType === "mix" &&
          <>
            {linkViewComponent}
            {imgViewComponent}
          </>
        }
      </div>
    )
  }, [moduleId])

  const expandImgComponent = useMemo(() => {
    return (
      <img src={expandImgUrl} alt="" draggable={false} className="pointer-events-none object-cover w-full h-auto lg:h-full lg:w-auto mx-auto" />
    )
  }, [expandImgUrl])

  return (
    <PageContainer>
      <div ref={parent}>
        {
          displayType === 1 && workId
          && <>{summaryComponent}</>
        }
        {
          displayType === 2 && moduleId
          && <>{detailsComponent}</>
        }
      </div>

      <Modal
        show={showModal}
        onClickButtonClose={handleCloseModal}
        onClickDivClose={handleCloseModal}
        onClose={handleCloseModal}
        buttonPrompt="Back"
        extend={true}
        content={expandImgComponent}
      />
    </PageContainer>
  )
}

export default WorkDetails

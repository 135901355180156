import Footer from "../Footer.component"

const PageContainer = ({children}) => {
  return (
    <div className="w-full 2xl:max-w-7xl pt-[calc(73.69px+3rem)] md:pt-[calc(106.81px+3rem)] mx-auto px-6 h-full flex flex-col justify-between">
      {children}
      <Footer />
    </div>
  )
}

export default PageContainer
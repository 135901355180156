

import { configureStore } from "@reduxjs/toolkit";
import searchSlice from "./slices/searchSlice";
import modalSlice from "./slices/modalSlice";
import slideOverSlice from "./slices/slideOverSlice";

const store = configureStore({
  reducer: {
    search: searchSlice,
    modal: modalSlice,
    slideOver: slideOverSlice,
  }
})

export default store
import PageContainer from "./containers/PageContainer.component"
import { useNavigate } from "react-router-dom"

const NotFound = () => {

  const navigate = useNavigate()

  return (
    <PageContainer>
      <div className="mx-auto max-w-max my-auto">
          <main className="sm:flex">
            <p className="text-4xl font-bold tracking-tight text-signature_yellow sm:text-5xl">403</p>
            <div className="sm:ml-6">
              <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                <h1 className="text-4xl font-bold tracking-tight sm:text-5xl">Page not ready</h1>
                <p className="mt-1 text-base text-gray-500">Page is under construction. Please check back later</p>
              </div>
              <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                <p
                  onClick={() => {navigate('/')}}
                  className="inline-flex items-center rounded-md border border-transparent bg-signature_yellow px-4 py-2 text-sm font-medium text-white shadow-sm
                  hover:bg-stone-900 transition duration-300 cursor-pointer"
                >
                  Go back home
                </p>
              </div>
            </div>
          </main>
        </div>
    </PageContainer>
  )
}

export default NotFound
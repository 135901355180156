import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  show: false,
  show1: false, //In case there are more than 2 slide overs in one component
  show2: false, //In case there are more than 3 slide overs in one component
}

export const slideOverSlice = createSlice({
  name: 'slide-over',
  initialState,
  reducers: {
    toggleSlideOver: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    }
  }
})

export const { toggleSlideOver } = slideOverSlice.actions

export default slideOverSlice.reducer
import { SpinnerCircular } from "spinners-react"

const LoadingState = ({color, secondaryColor, usePadding}) => {
    return (
        <div className={`w-full flex justify-center items-center z-[500] ${usePadding && "h-full p-20"}`}>
            <SpinnerCircular size={35} thickness={100} speed={100} color={color || "black"} secondaryColor={secondaryColor || "rgba(0, 0, 0, 0.44)"} />
        </div>
    )
}

export default LoadingState
import { ReactComponent as GithubIcon } from '../../assets/icons/github.svg'
import { ReactComponent as LinkedInIcon } from '../../assets/icons/linkedin.svg'
import { ReactComponent as GmailIcon } from '../../assets/icons/gmail.svg'

const navigation = [
  {
    name: 'GitHub',
    href: 'https://github.com/tuanminh160302',
    icon: GithubIcon,
  },
  {
    name: 'Dribbble',
    href: 'https://www.linkedin.com/in/steve-nguyen-a33607153/',
    icon: LinkedInIcon,
  },
  {
    name: 'Dribbble',
    href: 'mailto:canhtuan09@gmail.com',
    icon: GmailIcon,
  },
]

const Footer = () => {
  return (
    <footer className="bg-signature py-8">
      <div className="max-w-7xl mx-auto w-full px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
        <div className="flex justify-center space-x-4 md:order-2">
          {navigation.map((item, index) => (
            <a key={index} href={item.href} target="_blank" className="text-gray-400 hover:text-gray-500">
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-5 w-5 fill-gray-200 cursor-pointer hover:fill-signature_yellow transition duration-300" aria-hidden="true" />
            </a>
          ))}
        </div>
        <div className="mt-8 md:order-1 md:mt-0">
          <p className="text-center text-xs text-gray-400">&copy; {new Date().getFullYear()} Steve Nguyen. All rights reserved.</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
import { DocumentChartBarIcon, EyeIcon } from '@heroicons/react/20/solid'
import { useDispatch, useSelector } from 'react-redux'
import PageContainer from '../components/shared/containers/PageContainer.component'
import { toggleSlideOver } from '../redux/slices/slideOverSlice'
import SlideOver from "../components/shared/layovers/SlideOver.component"
import WorkSummary from '../components/organisms/WorkSummary.component'
import { works } from '../data/workSummary.data'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const Work = () => {
  const show = useSelector(state => state.slideOver.show)
  const dispatch = useDispatch()
  const [selectedWork, setSelectedWork] = useState()
  const navigate = useNavigate()

  const handleOpenSlideOver = () => {
    dispatch(toggleSlideOver({ show: true }))
  }

  const handleCloseSlideOver = () => {
    dispatch(toggleSlideOver({ show: false }))
  }

  const handleSelectWork = (work) => {
    setSelectedWork(work)
  }

  const handleRedirectWork = (workId) => {
    navigate(`/work/${workId}`)
  }

  return (
    <PageContainer>
      <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {works.map((work) => (
          <li key={work.id} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-stone-900 shadow">
            <div className="flex w-full items-center justify-between space-x-6 p-6">
              <div className="flex-1 truncate">
                <div className="flex items-center space-x-3">
                  <h3 className="truncate text-sm font-medium text-white">{work.name}</h3>
                  <span className="inline-block flex-shrink-0 rounded-full bg-red-100 px-2 py-0.5 text-xs font-medium text-red-800">
                    {work.location}
                  </span>
                </div>
                <p className="mt-1 truncate text-sm text-gray-400">{work.description}</p>
              </div>
            </div>
            <div>
              <div className="-mt-px flex divide-x divide-gray-200">
                <div className="flex w-0 flex-1">
                  <p
                    onClick={() => {
                      handleOpenSlideOver();
                      handleSelectWork(work)
                    }}
                    className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-white cursor-pointer hover:bg-stone-800 transition duration-300"
                  >
                    <DocumentChartBarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    Summary
                  </p>
                </div>
                <div className="-ml-px flex w-0 flex-1">
                  <p 
                    onClick={() => {handleRedirectWork(work.id)}}
                    className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-white cursor-pointer hover:bg-stone-800 transition duration-300"
                  >
                    <EyeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    Details
                  </p>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>

      <SlideOver 
        show={show}
        onClose={handleCloseSlideOver}
        onClickButtonClose={handleCloseSlideOver}
        title="Work Summary"
        content={<WorkSummary work={selectedWork}/>}
      />
    </PageContainer>
  )
}

export default Work

import { classNames } from "../../utils/classNames"
import Card from "../shared/containers/Card.component"

const WorkSummary = ({ work, className, ...rest }) => {
  console.log(work)
  const works = work.work
  return (
    <div
      className={classNames(
        ``,
        className
      )}
      {...rest}
    >
      <div className="flex flex-col space-y-4">
        <Card
          className="border-2 border-signature_yellow"
        >
          <div className="flex flex-col space-y-2">
            <p className="text-xs">
              ID:
              {" "}
              <span className="leading-5 font-light text-gray-400">{work?.id}</span>
            </p>

            <p className="text-xs">
              Name:
              {" "}
              <span className="leading-5 font-light text-gray-400">{work?.name}</span>
            </p>

            <p className="text-xs">
              Description:
              {" "}
              <span className="leading-5 font-light text-gray-400">{work?.description}</span>
            </p>

            <p className="text-xs">
              Location:
              {" "}
              <span className="leading-5 font-light text-gray-400">{work?.location}</span>
            </p>
          </div>
        </Card>
        <p className="text-sm font-medium">Modules</p>
        {
          works?.map((w, idx) => (
            <Card
              key={idx}
              title={w.name}
            >
              <div className="flex flex-col space-y-2">
                <p className="text-xs">
                  Description:
                  {" "}
                  <span className="leading-5 font-light text-gray-400">{w?.description}</span>
                </p>

                <p className="text-xs">
                  Module Type:
                  {" "}
                  <span className="leading-5 font-light text-gray-400">{w?.moduleType}</span>
                </p>

                <p className="text-xs">
                  Product Type:
                  {" "}
                  <span className="leading-5 font-light text-gray-400">{w?.productType}</span>
                </p>
              </div>
            </Card>
          ))
        }
      </div>
    </div>
  )
}

export default WorkSummary

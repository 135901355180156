import {ReactComponent as LogoSVG} from '../../assets/logo.svg'
import {Bars2Icon} from '@heroicons/react/20/solid'
import {getModifierKey} from '../../utils/getModifierKey'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { toggleSearch } from '../../redux/slices/searchSlice'

const Header = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const address = location.pathname.split("/").at(-1)
  console.log(address)

  return (
    <div className='fixed top-0 left-0 w-full flex flex-row justify-between lg:justify-start items-center 
      px-6 py-6 md:px-16 md:py-8 xl:px-24 bg-signature z-20'>
      <LogoSVG className='w-12 md:w-20 h-auto cursor-pointer' onClick={() => {navigate('/')}}/>
      <button className='px-4 py-2 text-xs bg-stone-900 rounded-md ml-2 md:ml-6 hover:bg-stone-800 duration-300'
        onClick={() => {dispatch(toggleSearch({show: true}))}}
        >Go to...
        <span className='text-gray-500 ml-3'>{getModifierKey()}+k</span>
      </button>
      <Bars2Icon className='h-5 w-5 sm:hidden ml-auto'/>
      <div className='hidden sm:flex sm:flex-row shadow rounded-full py-3 px-8 bg-stone-900 space-x-5 ml-auto'>
        <p className={`text-xs cursor-pointer hover:text-signature_yellow transition duration-300 ${address === "about" && "text-signature_yellow"}`}
          onClick={() => {navigate('/about')}}>About</p>
        <p className={`text-xs cursor-pointer hover:text-signature_yellow transition duration-300 ${address === "work" && "text-signature_yellow"}`}
          onClick={() => {navigate('/work')}}>Work</p>
        <p className={`text-xs cursor-pointer hover:text-signature_yellow transition duration-300 ${address === "art" && "text-signature_yellow"}`}
          onClick={() => {navigate('/art')}}>Art</p>
        <p className={`text-xs cursor-pointer hover:text-signature_yellow transition duration-300 ${address === "tech" && "text-signature_yellow"}`}
          onClick={() => {navigate('/tech')}}>Tech</p>
        <p className={`text-xs cursor-pointer hover:text-signature_yellow transition duration-300 ${address === "uses" && "text-signature_yellow"}`}
          onClick={() => {navigate('/uses')}}>Uses</p>
      </div>
    </div>
  )
}

export default Header
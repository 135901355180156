import PageContainer from "../components/shared/containers/PageContainer.component"
import ArtCard from "../components/organisms/ArtCard.component"

const pieces = [
  {
    name: "Vibing room",
    date: "11/06/2022",
    skill: ["threejs", "cinema4d"],
    imgUrl: "https://i.imgur.com/42Ckegn.png",
    id: "ez3H2MdBQr4VHefr",
    details: {
      polyCount: 260521,
      fileSize: "5MB",
      loadTime: "10.2s"
    }
  },
  {
    name: "Japanese Zen Garden",
    date: "11/06/2022",
    skill: ["threejs", "cinema4d"],
    imgUrl: "https://i.imgur.com/ZCcLxkc.png?1",
    id: "32OlNlCHiSITWdAF",
    details: {
      polyCount: 25977,
      fileSize: "1.7MB",
      loadTime: "3.6s"
    }
  },
  {
    name: "Future paint shop",
    date: "11/06/2022",
    skill: ["threejs", "cinema4d"],
    imgUrl: "https://i.imgur.com/5TwmW9M.png",
    id: "i67WJnkWM0Pk2SiJ",
    details: {
      polyCount: 18097,
      fileSize: "2.9MB",
      loadTime: "6.1s"
    }
  },
  {
    name: "Truck playground",
    date: "11/06/2022",
    skill: ["threejs", "cinema4d"],
    imgUrl: "https://i.imgur.com/Nlzj5bg.png",
    id: "JpQvHzHS5uCnwSMn",
    details: {
      polyCount: 5199,
      fileSize: "830kB",
      loadTime: "1.9s"
    }
  },
]

const Art = () => {
  return (
    <PageContainer> 
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-6">
        {
          pieces.map((p, index) => (
            <ArtCard key={index} art={p}/>
          ))
        }
      </div>
    </PageContainer> 
  )
}

export default Art
import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    show: false
}

export const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        toggleSearch: (state, action) => {
            return {
                ...state,
                ...action.payload
            }
        }
    }
})

export const {toggleSearch} = searchSlice.actions

export default searchSlice.reducer
import { Combobox, Dialog, Transition, Switch } from '@headlessui/react'
import { XCircleIcon, MagnifyingGlassIcon, HomeIcon, InformationCircleIcon, ChartBarSquareIcon, WrenchIcon, CommandLineIcon, FolderIcon } from '@heroicons/react/20/solid'
import { useSelector, useDispatch } from 'react-redux'
import { toggleSearch } from '../../redux/slices/searchSlice'
import { classNames } from '../../utils/classNames'
import { useNavigate } from 'react-router-dom'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import { useState, Fragment } from 'react'

const sites = [
  {
    __typename: "Site",
    name: "Home",
    icon: HomeIcon,
    pathname: "/"
  },
  {
    __typename: "Site",
    name: "About",
    icon: InformationCircleIcon,
    pathname: "/about"
  },
  {
    __typename: "Site",
    name: "Work",
    icon: FolderIcon,
    pathname: "/work"
  },
  {
    __typename: "Site",
    name: "Art",
    icon: ChartBarSquareIcon,
    pathname: "/art"
  },
  {
    __typename: "Site",
    name: "Tech",
    icon: CommandLineIcon,
    pathname: "/tech"
  },
  {
    __typename: "Site",
    name: "Uses",
    icon: WrenchIcon,
    pathname: "/uses"
  }
]

const SearchComponent = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const show = useSelector(state => state.search.show)
  const [sitesParent] = useAutoAnimate()

  const [rawQuery, setRawQuery] = useState('')

  const query = rawQuery.toLowerCase().replace(/^[#/>]/, '')

  const handleItemRedirect = (item) => {
    if (item.__typename === "Person") {
      navigate(`/${item.email.split("@").at(0)}`)
    } else if (item.__typename === "Site") {
      navigate(`${item.pathname}`)
    }
    dispatch(toggleSearch({ show: false }))
  }

  return (
    <Transition.Root show={show} as={Fragment} afterLeave={() => setRawQuery('')} appear>
      <Dialog as="div" className="relative z-[55]" onClose={() => { dispatch(toggleSearch({ show: false })) }}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black_rgba bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 
              transition-all">
              <Combobox onChange={(item) => { handleItemRedirect(item) }}>
                <div className="relative">
                  <MagnifyingGlassIcon
                    className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <Combobox.Input
                    className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 text-xs"
                    placeholder="Go to..."
                    onChange={(event) => setRawQuery(event.target.value)}
                  />
                </div>

                <div ref={sitesParent}>
                  {
                    sites.map((site, index) => (
                      <Combobox.Option
                        key={index}
                        value={site}
                        className={({ active }) =>
                          classNames(
                            'flex select-none items-center px-4 py-3 text-gray-600 cursor-pointer transition duration-150',
                            active && 'bg-gray-900 !text-white'
                          )
                        }
                      >
                        <site.icon className="h-4 w-4" aria-hidden="true" />
                        <span className="ml-2 flex-auto truncate text-xs">{site.name}</span>
                        <span className="flex-none text-xs ml-auto">{site.pathname}</span>
                      </Combobox.Option>
                    ))
                  }
                </div>

                {rawQuery === '?' && (
                  <div className="py-14 px-6 text-center text-xs sm:px-14">
                    <XCircleIcon className="mx-auto h-4 w-4 text-gray-400" aria-hidden="true" />
                    <p className="mt-4 font-semibold text-gray-900">Help with searching</p>
                    <p className="mt-2 text-gray-500">
                      Use this tool to quickly search for users and projects across our entire platform. You can also
                      use the search modifiers found in the footer below to limit the results to just users or projects.
                    </p>
                  </div>
                )}

                {query !== '' && rawQuery !== '?' && (
                  <div className="py-14 px-6 text-center text-xs sm:px-14">
                    <XCircleIcon className="mx-auto h-4 w-4 text-gray-400" aria-hidden="true" />
                    <p className="mt-4 font-semibold text-gray-900">No results found</p>
                    <p className="mt-2 text-gray-500">We couldn’t find anything with that term. Please try again.</p>
                  </div>
                )}

              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default SearchComponent
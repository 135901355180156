import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  show: false,
  show1: false, //In case there are more than 2 modals in one component
  show2: false,
}

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    toggleModal: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    }
  }
})

export const { toggleModal } = modalSlice.actions

export default modalSlice.reducer

import './App.scss';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Header from './components/shared/Header.component';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toggleSearch } from './redux/slices/searchSlice';
import SearchComponent from './components/shared/Search.component';
import Art from './pages/Art';
import ThreeJSRender from './components/organisms/ThreeJSRender.component';
import NotFound from './components/shared/NotFound.component';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import Work from './pages/Work';
import WorkDetails from './components/organisms/WorkDetails.component';
import useCleanupLayover from './hooks/useCleanupLayovers';
import useRedirectPage from './hooks/useRedirectPage';

function App() {

  const dispatch = useDispatch()
  const [parent] = useAutoAnimate()

  useCleanupLayover()
  useRedirectPage()

  useEffect(() => {
    document.addEventListener('keydown', detectKeydown, true)
  }, [])

  const detectKeydown = (e) => {
    if ((e.ctrlKey || e.metaKey) && e.key === "k") {
      e.preventDefault()
      dispatch(toggleSearch({ show: true }))
    }
  }

  return (
    <div className="h-full w-full bg-signature text-gray-300">
      <div ref={parent} className='w-full md:px-16 h-full mx-auto overflow-x-hidden'>
        <Header />
        <SearchComponent />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/work' element={<Work />} />
          <Route path='/work/:workId' element={<WorkDetails />} />
          <Route path='/work/:workId/:modulesId' element={<WorkDetails />} />
          <Route path='/about' element={<NotFound />} />
          <Route path='/tech' element={<NotFound />} />
          <Route path='/uses' element={<NotFound />} />
          <Route path='/art' element={<Art />} />
          <Route path='/art/3js/:id' element={<ThreeJSRender />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;

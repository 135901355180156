export const works = [
  {
    id: "ab112e02-e009-4b33-bf0a-b4fb0f4dbee4",
    name: 'AVA 363: 3D Modeling',
    description: 'N/A',
    location: 'Union College',
  },

  {
    id: "d007d7cd-13d5-4030-b977-5d03fa3c6db6",
    name: 'AVA 282: Digital Aesthetics',
    description: '5 Projects',
    location: 'Union College',
    work: [
      {
        id: 0,
        moduleType: "Project",
        name: "10 Bad Designs",
        productType: "img",
        description: "Find 10 bad designs",
        imgs: [
          {title: "", source: "https://static.demilked.com/wp-content/uploads/2022/02/funny-pics-really-bad-design-memes-2.jpeg"},
          {title: "", source: "https://static.boredpanda.com/blog/wp-content/uploads/2015/11/poor-design-decisions-481__605.jpg"},
          {title: "", source: "https://static.boredpanda.com/blog/wp-content/uploads/2015/11/poor-design-decisions-461__605.jpg"},
          {title: "", source: "https://static.boredpanda.com/blog/wp-content/uploads/2015/11/poor-design-decisions-33__605.jpg"},
          {title: "", source: "https://static.boredpanda.com/blog/wp-content/uploads/2015/11/poor-design-decisions-45__605.jpg"},
          {title: "", source: "https://static.boredpanda.com/blog/wp-content/uploads/2015/11/poor-design-decisions-201__605.jpg"},
          {title: "", source: "https://static.boredpanda.com/blog/wp-content/uploads/2015/11/poor-design-decisions-31__605.jpg"},
          {title: "", source: "https://static.boredpanda.com/blog/wp-content/uploads/2015/11/poor-design-decisions-40__605.jpg"},
          {title: "", source: "https://static.boredpanda.com/blog/wp-content/uploads/2015/11/poor-design-decisions-37__605.jpg"},
          {title: "", source: "https://static.boredpanda.com/blog/wp-content/uploads/2015/11/poor-design-decisions-32__605.jpg"},
        ]
      },
      {
        id: 1,
        moduleType: "Project",
        name: "UNIFYING PRINCIPLES",
        productType: "img",
        description: `Using Illustrator and the design principles that you read about in the article Unifying Principles of Design, create a minimum of two designs for each of the principles: Repetition, Variety, Rhythm, Balance, Emphasis, and Economy. The size of each design should be 8"x10", some in portrait and others in landscape orientation. `,
        imgs: [
          {title: "", source: "https://i.imgur.com/V8Qm3ep.jpg"},
          {title: "", source: "https://i.imgur.com/4MRFRGW.jpg"},
          {title: "", source: "https://i.imgur.com/p2Yk0Zl.jpg"},
          {title: "", source: "https://i.imgur.com/GvzG7al.jpg"},
          {title: "", source: "https://i.imgur.com/ETu0Ark.jpg"},
          {title: "", source: "https://i.imgur.com/MUD1TGa.jpg"},
          {title: "", source: "https://i.imgur.com/DGlrKe5.jpg"},
          {title: "", source: "https://i.imgur.com/Mr56jvR.jpg"},
          {title: "", source: "https://i.imgur.com/lsfxJTW.jpg"},
          {title: "", source: "https://i.imgur.com/9jRFSno.jpg"},
          {title: "", source: "https://i.imgur.com/ubrwww4.jpg"},
          {title: "", source: "https://i.imgur.com/17qAbJm.jpg"},
        ]
      },
      {
        id: 2,
        moduleType: "Project",
        name: "Your Logo",
        productType: "link",
        description: `Using Illustrator, create a minimum of three logos for each of the three companies found below. Things to think about in your design include simple, versatile, scalable, memorable, relevant`,
        links: [
          {title: "Logo Presentation: Spasm", source: "https://drive.google.com/file/d/16KYHhmfNvMnZqpqwKHmnm4F92-asO5dt/view?usp=share_link"},
          {title: "Logo Presentation: On Electro", source: "https://drive.google.com/file/d/1IUjD8d6RyzWUzfeXrBE7cgeSXBZ0ExAL/view?usp=share_link"},
          {title: "Logo Presentation: Good Lunch", source: "https://drive.google.com/file/d/1DHhgkmE3MGYZBUGGbolSPnWcvw94TLyH/view?usp=share_link"},
        ]
      },
      {
        id: 3,
        moduleType: "Project",
        name: "A Farmer's Market",
        productType: "link",
        description: `Using Illustrator and Photoshop, create three different posters for the event below. In each poster design a different demographic should be considered. These demographics should be populations that are in the 17-28, 29-49, and 50+ range. Come up with a slogan for each poster that focuses on the sensibilities of the demographic. The size of the posters should be 16"x20" in either landscape or portrait. `,
        links: [
          {title: "Demographic 19-27_1", source: "https://drive.google.com/file/d/1Wf89d7u0SQAyK-i8SjRmICi8V1Y9pC6E/view?usp=share_link"},
          {title: "Demographic 19-27_2", source: "https://drive.google.com/file/d/1l-zUJ2ZyEtGtUNePQM0q2KrFqHehPiy-/view?usp=share_link"},
          {title: "Demographic 19-27_3", source: "https://drive.google.com/file/d/1B8KbDWbzvdDkSH118uQk613U64HZthnb/view?usp=share_link"},
          {title: "Demographic 29-49", source: "https://drive.google.com/file/d/1ZRod-keLVC8VyT7zyiUf2M7BNn7hIf_G/view?usp=share_link"},
          {title: "Demographic 50+", source: "https://drive.google.com/file/d/1e-0S1mREcZYJSEz2NgEy3Y_HYGoWD-8Y/view?usp=share_link"},
        ]
      },
      {
        id: 4,
        moduleType: "Project",
        name: "Fizzle Fold",
        productType: "mix",
        description: `For this assignment you will be creating designs for a fictional energy drink called Fizzle or a  Coffee company called La Castellana. You can also choose to do both! In addition to creating the designs, including logos, that will be fit over the products container, you must  make the art for the product's packaging. The packaging must accommodate at least four bottles or on full bag of coffee beans. The slogan and the target demographic for the product is up to you. `,
        imgs: [
          {title: "", source: "https://i.imgur.com/6sDHQpF.jpg"},
          {title: "", source: "https://i.imgur.com/Z2DA1yT.jpg"},
          {title: "", source: "https://i.imgur.com/AAZBnfb.jpg"},
          {title: "", source: "https://i.imgur.com/pQzl1lE.jpg"},
          {title: "", source: "https://i.imgur.com/ed8I8in.jpg"},
          {title: "", source: "https://i.imgur.com/fqS1MNh.jpg"},
          {title: "", source: "https://i.imgur.com/WgQegFB.jpg"},
          {title: "", source: "https://i.imgur.com/rlqwuGR.jpg"},
          {title: "", source: "https://i.imgur.com/zBktdz8.jpg"},
          {title: "", source: "https://i.imgur.com/Svo7VU3.jpg"},
          {title: "", source: "https://i.imgur.com/Eb1dO2Z.jpg"},
          {title: "", source: "https://i.imgur.com/Yi18Ca9.jpg"},
          {title: "", source: "https://i.imgur.com/KKVODCe.jpg"},
          {title: "", source: "https://i.imgur.com/NZkT4iO.jpg"},
          {title: "", source: "https://i.imgur.com/G0NMyIE.jpg"},
          {title: "", source: "https://i.imgur.com/EObYMRR.jpg"},
          {title: "", source: "https://i.imgur.com/TY0Amsy.jpg"},
        ],
        links: [
          {title: "La Castellana Presentation", source: "https://drive.google.com/file/d/1ukCuZrvwWMVnqPTWnzTirL3Rr0QesG4h/view?usp=share_link"},
          {title: "Agent Adrenaline Presentation", source: "https://drive.google.com/file/d/1mPcEIJXk18raqKbJZO0EPryRNQLUTrG_/view?usp=share_link"},
        ]
      },  
      {
        id: 5,
        moduleType: "Project",
        name: "Web Page Layout",
        productType: "link",
        description: `Using some of the content from the previous Fizzle assignment, you will be creating the layout for the products webpage. The site will not need to function, just show the design and flow of information, including the navigation. A minimum of two pages must be laid out.`,
        links: [
          {title: "Agent Adrenaline Website", source: "https://agent-adrenaline.netlify.app/"},
          {title: "La Castellana Website", source: "https://la-castellana.netlify.app/"},
        ]
      },
    ]
  },

  {
    id: "61f764e2-5033-4c67-8c41-9b49754cc8ca",
    name: 'AVA 370: Robotic Arts',
    description: 'N/A',
    location: 'Union College',
    work: [
      {
        id: 0,
        moduleType: "Project",
        name: "Robotic Arts Compilation",
        productType: "mix",
        description: "This module contains all the media for the class",
        imgs: [
          {title: "", source: "https://i.imgur.com/wsTZzg6.jpg"},
        ],
        links: [
          {title: "Line Following Car 1", source: "https://drive.google.com/file/d/1a_aTR2lkkQlHbpEhrX5HBMzHE_9Zvdbo/view?usp=sharing"},
          {title: "Line Following Car 2", source: "https://drive.google.com/file/d/1voFwqt7tcT_XAMcj82kE2_tYslqhYfzS/view?usp=sharing"},
          {title: "Line Following Car 3", source: "https://drive.google.com/file/d/1DA1aRzqKKaC00Rsu-UislAISZfqXrTfY/view?usp=sharing"},
          {title: "Line Following Car 4", source: "https://drive.google.com/file/d/1FB4uEZnf3VrdFNkpIHw7fYdgxBU0jCDl/view?usp=sharing"},
          {title: "Final Project Prototype 1", source: "https://drive.google.com/file/d/1Mp_vH64JIKjI_HGc7vBQqpYhq2hEkf9Z/view?usp=sharing"},
          {title: "Final Project Prototype 2", source: "https://drive.google.com/file/d/1qSoaAVI06YerBkg0lv1PWMWm1aHDXxgi/view?usp=sharing"},
          {title: "Final Project Prototype 3", source: "https://drive.google.com/file/d/1NOUiLyTRU1d0fVelyg-xQ1vjkg-uXkk1/view?usp=sharing"},
          {title: "Final Project Prototype 4", source: "https://drive.google.com/file/d/1PmE991e_cZOwI5bw6vtuiJtHy80M671f/view?usp=sharing"},
          {title: "Final Project 1", source: "https://drive.google.com/file/d/1ucN2312i4Onrv3uBwu0Z1ZHuZZl2y95Q/view?usp=sharing"},
          {title: "Final Project 2", source: "https://drive.google.com/file/d/1X9NKmRFFkwMo6N5xSILT_aO3Xkv_gAsK/view?usp=sharing"},
        ]
      },
    ]
  },
]
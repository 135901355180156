import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"

const useRedirectPage = (history) => {
  const location = useLocation()
  const pathname = location.pathname
  const navigate = useNavigate()

  useEffect(() => {
    switch (pathname) {
      case "/robotic-art":
        return navigate("/work/61f764e2-5033-4c67-8c41-9b49754cc8ca", { replace: true })
      case "/work/ab112e02-e009-4b33-bf0a-b4fb0f4dbee4":
        return navigate("/art", { replace: true })
    }
  }, [location])
}

export default useRedirectPage

import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { classNames } from '../../../utils/classNames'

/**
 * A modal that will be displayed upon all other contents
 * @param show Open/close modal
 * @param title Modal title
 * @param content Modal content
 * @param buttonPrompt Button prompt
 * @param onClose Execute when user inputs ESC
 * @param onClickButtonClose Execute when user clicks the button
 * @param onClickDivClose Execute when user clicks outside modal
 * @param extend Provide to achieve large size modal
 */
const Modal = ({ show, onClose, content, onClickDivClose, buttonPrompt, onClickButtonClose, extend, title, buttonPrompt2, onClickButtonClose2 }) => {
  return (
    <div>
      <Transition.Root show={show} as={Fragment}>
        <Dialog as="div" className="relative z-[500]" onClose={() => { onClose && onClose() }}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black_rgba bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <div className='absolute h-full w-full bg-transparent top-0 left-0'
                onClick={() => { onClickDivClose && onClickDivClose() }}></div>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-150"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className={`z-10 relative bg-signature rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl 
                                    transform transition-all sm:my-8 w-full max-w-md ${extend ? "sm:max-w-7xl" : "sm:max-w-lg"} 
                                    sm:p-6 self-center justify-self-center`}>
                  <Dialog.Title as="h3" className="text-sm leading-6 font-medium text-white mb-4">
                    {title}
                  </Dialog.Title>
                  <div className={`${extend ? "h-[70vh]" : "max-h-[500px]"} overflow-y-auto`}>
                    {content}
                  </div>
                  {
                    buttonPrompt && <div className="mt-2">
                      <button
                        type="button"
                        className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-3 
                                            bg-red-700 text-xs font-medium text-white hover:bg-red-500 transition duration-150"
                        onClick={() => { onClickButtonClose && onClickButtonClose() }}
                      >
                        {buttonPrompt}
                      </button>
                    </div>
                  }
                  {
                    buttonPrompt2 && <div className="mt-2">
                      <button
                        type="button"
                        className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-3 
                                            bg-signature text-xs font-medium text-white hover:bg-slate-800 transition duration-150"
                        onClick={() => { onClickButtonClose2 && onClickButtonClose2() }}
                      >
                        {buttonPrompt2}
                      </button>
                    </div>
                  }
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  )
}

export default Modal